/* got the color themes here https://colorhunt.co/palettes/popular*/

.App {
  text-align: center;
  background: #f0f5f9;
  color: #52616b;
  font-family: 'David Libre', serif;
  text-align: center;
}

.App-header {
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'David Libre', serif;
  text-align: center;
}

.App-body{
  font-size: calc(10px + 2vmin);
  min-height: 100vh;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
}

.App-link {
  color: #52616b;
  display: block;
}

.padded-top {
  padding-top: 3em;
  font-family: 'Lora', serif;
}

.image-container {
  display: inline-block;
  width: 50%;
}


.lang {
  min-width: 100%;
  min-height: 100%;
  overflow-x: auto;
  display: flex;
  top: 0;
  left: 0;
  border-radius: 50%;
}

.lang--content {
  min-width: 150px;
  min-height: 100px;
  margin: 5px;
}

.Languages{
  color: #69779b;
  font-family: 'David Libre', serif;
  text-align: center;
}

.grayscale {
  filter: grayscale(100%);
  max-width: 100%;
  max-height: 100vh;
  margin: auto;

}

.bg-image {
  /* The image used */
  background-image: url("/src/images/google.jpg");

  /* Add the blur effect */
  filter: blur(5px);
  -webkit-filter: blur(5px);

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

.bg-text {
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}

.center {
  margin: auto;
  width: 50%;
  text-align: center;
  padding: 10px;
  color: #52616b
}

.contactStyle{
  font-family: 'David Libre', serif;
  text-align: center;

}

a {
  font-family: 'David Libre', serif;
  color: #52616b;
}

a:hover {
  font-family: 'David Libre', serif;
  color: #52616b;
}

.emailFields {
  font-family: 'David Libre', serif;
  text-align: center;
}

.proficiencyLabel {
  font-family: 'David Libre', serif;
  text-align: center;
}

.subBtn {
  text-align: center;
  background: #f0f5f9;
   font-family: 'David Libre', serif;
   text-align: center;
}

.subBtn:hover {
  text-align: center;
  background: #f0f5f9;
  background-color: red;
   color: white;
   font-family: 'David Libre', serif;
   text-align: center;
}

.aboutImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
}

.aboutImgReg {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
