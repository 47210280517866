.db {
    display: block;
}

.dib {
    display: inline-block;
}

.h2 {
    height: 2rem;
}

.link {
    text-decoration: none;
    transition: color .15s ease-in;
}

.link:link {
    transition: color .15s ease-in;
}

.link:hover {
    transition: color .15s ease-in;
}

.link:active {
    transition: color .15s ease-in;
}

.link:focus {
    transition: color .15s ease-in;
    outline: 1px dotted currentColor;
}

.w2 {
    width: 2rem;
}

.near-black {
    color: #1e2022;
}

.hover-silver:hover {
    color: #1e2022;
}

.hover-silver:focus {
    color: #1e2022;
}

.pv4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.ph3 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.mh3 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.tc {
    text-align: center;
}

.f6 {
    font-size: .875rem;
}